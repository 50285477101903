import React from "react"

const TheCommunityRightContent = () => (
  <>
    <div className="content content-community right">
      <div className="content-timeline-community">
        <p className="para">
          <span className="bullet"></span>
          Add your voice by writing or recording your answer.
        </p>
        <p className="para">
          <span className="bullet"></span>
          Your answers and ideas, along with others from around the world, will
          be added to a global study.
        </p>
        <p className="para">
          <span className="bullet"></span>
          Findings from the study will then be shared around the globe and will
          inform actions, tools &amp; initiatives designed to help more women
          reach their full potential.
        </p>
      </div>
    </div>
  </>
)

export default TheCommunityRightContent
