import React from "react"

import "./the-community.css"

const TheCommunityLeftContent = props => (
  <>
    <div className="content content-community">
      <h3 className="title title-community">
        Women&#8217;s voices are powerful, and they need to be heard.
        That&#8217;s why we&#8217;re asking women everywhere to use their voices
        so that together we can help shape tomorrow.
      </h3>

      <div className="d-lg-none">
        <div className="content-timeline-community">
          <p className="para">
            <span className="bullet"></span>
            Add your voice by writing or recording your answer.
          </p>
          <p className="para">
            <span className="bullet"></span>
            Your answers and ideas, along with others from around the world,
            will be added to a global study.
          </p>
          <p className="para">
            <span className="bullet"></span>
            Findings from the study will then be shared around the globe and
            will inform actions, tools &amp; initiatives designed to help more
            women reach their full potential.
          </p>
        </div>
      </div>
      <div
        className="transition-control"
        onTransitionEnd={() => props.setScrollControl(true)}
      >
        Transition Control Element
      </div>
    </div>
  </>
)

export default TheCommunityLeftContent
